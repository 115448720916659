.header{
    width: 100%;
    height: 90px;
    background-color: #8c1515;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.header__items{
    display: flex;
    padding-top: 5px;
    padding-left: 5px;
}
.lan{
    display: flex;
    align-items: center;
  
    margin-right: 20px;
}
.lan>p{
    color: white;
}
.lan>button{
    border: none;
    padding: 4px 8px;
    margin: 4px;
    background: none;
    font-size: 15px;
    color: white;
    margin-bottom: 1px;
    transition: .3s;
}
.lan>button:hover{
    font-size: 16px;
    background-color: white;
    color: #8c1515;
}
.lan>button:focus{
    outline: none;
}
.logop{
    object-fit: contain;
    height: 80px;
    transition: 0.5s;
    margin-right: 20px;
}
a{
    text-decoration: none;
}

ul{
    margin-top: 40px;
    display: flex;
    list-style-type: none;
}
.rotate{
    transform: rotate(180deg);
}
.rotateOff{
    transform: rotate(0deg);
}
li{
    padding: 5px 20px;
    color: azure;
    font-size: 20px;
    margin-right: 10px;
    transition: 500ms;
}
li:hover{
    color: #8c1515;
    background-color: azure;
}
.logop:hover{
    filter:brightness(1.3)
}

.mob__header{
    display: none;
}
.slidemenu__items{
    display: none;
}

@media screen and (max-width: 715px) {
    .mob__header{
        position: absolute;
        width: 100%;
        height: 55px;
        background-color: #8c1515;
        display: flex;
        justify-content: space-between;
        z-index: 999;
    }
    .header__items{
        display: none;
    }
    .lan{
        display:none;
    }
    .header{
        display:none;
    }
    .mob__logo{
        display: block;
        object-fit: contain;
        height: 48px;
        margin-top: 3px;
        margin-left: 3px;
        pointer-events: none;
    }
    .mob__menu{
        display: flex;
        align-items: center;
        color: white;
        font-size: 20px;
        margin-right: 10px;
        margin-top: -5px;
        padding-left: 10px;
        z-index: 1;
    }
    .mob__slideMenu{
        position: absolute;
        display: flex;
        right: 0px;
        top: 55px;
        z-index: 1;
        width: 200px;
        height: 300px;
        background-image: linear-gradient(#8c1515, #09425A);
        transition: ease-out .2s;
        transform: translateY(0%);
    }
    .mob__slideMenuOff{
        position: absolute;
        display: flex;
        right: 0px;
        top: 55px;
        z-index: 1;
        width: 200px;
        height: 300px;
        background-image: linear-gradient(#8c1515, #09425A);
        transition: ease-out .2s;
        transform: translateY(-100%);
    }
    .slidemenu__items{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        width: 60%;
        margin: 0 auto;
    }
    .slideMenu__lan{
        display: flex;
        align-items: center;
    }
    .slideMenu__lan>button{
        border: none;
        padding: 4px 8px;
        margin: 4px;
        background: none;
        font-size: 15px;
        color: white;
        margin-bottom: 1px;
        transition: .3s;
    }
    .slideMenu__lan>button:hover{
        background-color: white;
        color: #8c1515;
    }
    .slideMenu__lan>button:focus{
        outline: none;
    }
    .slideMenu__item{
        color: AZURE;
        font-size: 20px;
        margin-bottom: 5px;
        padding: 5px;
    }

}