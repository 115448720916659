.main-footer{
    max-width: 1300px;
    width: 100%;
    background-color: #8c1515;
    height: 60px;
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
}
.main-footer > p{
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding-top: 23px;
    color: White;
}